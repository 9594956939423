<template>
  <div class="login">
    <div class="login-content">
      <div class="logo-view">
        <img v-if="lange" class="logo-img" src="../static/img/logo-cn.png" />
        <img v-if="!lange" class="logo-img2" src="../static/img/logo-eng.png" />
        <div class="logo-title" style="color: #fff">
          {{ $t("loginPage.title") }}
        </div>
      </div>
      <div class="flex-row login-right">
        <el-button v-if="false" class="btn-black"
          >{{ $t("loginPage.experience") }} Demo</el-button
        >
        <div class="login-text">
          <span class="hover" @click="langeSwitch">{{
            lange ? "ENG" : "中"
          }}</span>
        </div>
      </div>
      <div class="login-view">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          style="padding-top: 20px; width: 380px"
        >
          <el-form-item prop="username">
            <el-input
              prefix-icon="el-icon-school"
              v-model="loginForm.username"
              type="text"
              :placeholder="$t('loginPage.phUserName')"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              v-model="loginForm.password"
              type="password"
              ref="loginForm.username"
              clearable
              :placeholder="$t('loginPage.phPassword')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code" class="code-box">
            <el-input
              prefix-icon="el-icon-picture-outline-round"
              v-model="loginForm.code"
              type="text"
              ref="loginForm.username"
              maxlength="4"
              :placeholder="$t('loginPage.phCheckCode')"
            ></el-input>
            <div class="code-img" v-html="codeSrc" @click="getCheckCode"></div>
          </el-form-item>
          <el-form-item>
            <el-button
              class="btn-black"
              :loading="loading"
              type="primary"
              @click="register"
              >{{ $t("loginPage.loginBtn") }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="version">{{ $t("loginPage.version") }} {{ version }}</div>
    </div>
    <div class="login-bg"></div>
  </div>
</template>
<script>
import rules from '../plugins/rules';
import { Loading } from 'element-ui';
import Storage from '../utils/storage';

export default {
  data() {
    return {
      version: localStorage.getItem("version") || "1.0.0",
      codeSrc: "",
      loginForm: {
        username: '',
        password: '',
        code: ''
      },
      //  效验规则
      loginRules: {
        username: [
          { required: true, trigger: 'blur', validator: rules.validateAccount }
        ],
        password: [
          { required: true, trigger: 'blur', validator: rules.validatePass }
        ],
        code: [
          { required: true, trigger: 'blur', validator: rules.validateCode }
        ],
      },
      loading: false,
      passwordType: 'password',
      loginType: this.$t('loginPage.investorLogin'),
      address: null,
      companyName: undefined,
      role: '4',
      initRole: 0,
      contractAddress: undefined,
      loadShow: undefined,
      lange: '',
      approved: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.getCheckCode();
    }, 1000);
    this.lange = Storage.getLocalItem('i18n-admin') === 'zh-cn';
  },
  methods: {
    getCheckCode() {
      const _this = this;
      this.$axios.get("/captcha/svg", {}).then((res) => {
        if (res) {
          _this.codeSrc = res;
        }
      });
    },
    async register() {
      let local = this.lange ? 'zh-cn' : 'zh-en';
      Storage.setLocalItem('i18n-admin', local);
      this.loading = true;
      const _this = this;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // add database
          _this.$axios.post("/login/admin", _this.loginForm).then(res => {
            _this.loading = false;
            _this.getCheckCode();
            if (res.code) {
              _this.$message.error(res.message);
            } else {
              this.$store.dispatch('auth/loginSuccess', {
                username: _this.loginForm.username,
                id: res.data.role,
                authorization: res.data.token,
              });
              _this.$store.dispatch('auth/isSide', true);
              // 根据返回的状态以及当前选中的角色，判断跳转路由 UNKNOWN:未认证;PROCESSING:认证中;CONFIRM:已认证;REJECT:认证拒绝;
              Storage.setLocalItem("isConfirm", true);
              sessionStorage.setItem("roleId", res.data.role);
              // 跳转错误页面
              _this.$router.replace({ path: "/admin-all" });
            }
          }).catch((err) => {
            _this.getCheckCode();
            _this.loading = false;
            _this.$message.error(err);
          });
        } else {
          _this.loading = false;
          return false;
        }
      });
    },
    menuSwich(command) {
      this.loginType = command;
    },
    // 语言切换
    langeSwitch() {
      this.lange = !this.lange;
      let local = this.lange ? 'zh-cn' : 'zh-en';
      Storage.setLocalItem('i18n-admin', local);
      this.$i18n.locale = local;
      this.$store.dispatch('auth/language', local);
      window.location.reload();
    }
  },
  watch: {
    contractAddress(value) {
      this.loadShow.close();
    }
  }
};
</script>

<style lang="scss">
.radio-view {
  .el-radio {
    margin-right: 10px;
  }
}
.code-box > .el-form-item__content {
  position: relative;
  z-index: 1;
  .code-img {
    cursor: pointer;
    position: absolute;
    z-index: 9;
    right: 0;
    top: 0;
    border-left: 1px solid #000;
    width: 150px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100% !important;
      height: 40px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
$light_gray: #fff;
$cursor: #fff;
.switch {
  position: absolute;
  right: 10px;
  top: 10px;
}
.login {
  min-width: 1000px;
  height: 100vh;
  background: linear-gradient(180deg, #d8f1ef 0%, #80d3cb 50%, #80d3cb 100%);
  .el-input {
    display: inline-block;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      caret-color: $cursor;
    }
  }
}
.login-content {
  position: relative;
  min-width: 1000px;
  max-width: 1600px;
  height: 100vh;
  margin: 0 auto;
  z-index: 9;
  .logo-view {
    position: absolute;
    bottom: 25%;
    left: 70px;
    font-size: 40px;
    font-family: "fontfmb";
    font-weight: 500;
    letter-spacing: 4px;
    .logo-title {
      padding-left: 15px;
    }
    .logo-img {
      width: 430px;
      height: 150px;
      margin-left: 0px;
    }
    .logo-img2 {
      width: 676px;
      height: 164px;
      margin-left: 0px;
    }
  }
  .login-tv {
    display: inline-block;
    width: 280px;
  }
  .logo-img {
    width: 480px;
    height: 370px;
    margin: 0 70px 0 30px;
  }
  .login-right {
    position: absolute;
    right: 80px;
    top: 80px;
    .login-text {
      font-size: 18px;
      font-weight: bold;
      margin-left: 26px;
      width: 40px;
      .hover {
        cursor: pointer;
      }
    }
  }
  .login-view {
    position: absolute;
    right: 80px;
    top: 120px;
    width: 440px;
    height: 280px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    .radio-view {
      width: 350px;
      padding: 11px 10px;
      border: 1px solid #80d3cb;
      background: #80d3cb;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
    }
    .btn-black {
      width: 100%;
    }
  }
}
.el-form {
  position: relative;
  max-width: 450px;
  padding: 30px 25px 0;
  margin: 0 auto;
  overflow: hidden;
}
.version {
  position: absolute;
  bottom: 78px;
  right: 68px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.login-bg {
  width: 100%;
  min-width: 1000px;
  min-height: 80%;
  background: url("../static/img/logo-bg.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
}
::v-deep .is-checked .el-radio__inner {
  border-color: #000000 !important;
  background: #000000 !important;
}
::v-deep .is-checked .el-radio__label {
  color: #000000 !important;
}
::v-deep .el-input__inner {
  border-color: #000000 !important;
}
</style>
